<template>
  <div class="currency-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="currencyListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('CURRENCY_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_NAME_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_NAME_FIELD_NAME" rules="required|max:128">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.name" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_ICON_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_ICON_FIELD_NAME" rules="required|max:256">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.icon" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_SYMBOL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <span class="form-control-plaintext">{{ currencyDetails.symbol }}</span>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_PLATFORM_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <span class="form-control-plaintext">{{ currencyDetails.platform }}</span>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_TYPE_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <span class="currency-type"><StakingBadge type="currency-type" :text="currencyDetails.type" /></span>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <CRow>
                      <CCol sm="12" lg="5" md="5">
                        <ValidationProvider name="CURRENCY_DETAILS_PAGE_STATUS_FIELD_NAME" rules="required">
                          <div slot-scope="{ errors }">
                            <select v-model="currencyDetails.status" class="form-control">
                              <option value>{{ $t('CURRENCY_DETAILS_PAGE_CHOSE_A_STATUS_LABEL') }}</option>
                              <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                            </select>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                      <CCol sm="12" lg="7" md="7">
                        <ValidationProvider name="CURRENCY_DETAILS_PAGE_WEB_MAINTENANCE_END_DATE_FIELD_NAME" rules="">
                          <div slot-scope="{ errors }">
                            <div role="group" class="form-group">
                              <date-picker
                                v-model="currencyDetails.web_maintenance_end_date"
                                valueType="date"
                                type="datetime"
                              ></date-picker>
                            </div>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_MOBILE_IOS_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <CRow>
                      <CCol sm="12" lg="5" md="5">
                          <ValidationProvider name="CURRENCY_DETAILS_PAGE_MOBILE_IOS_STATUS_FIELD_NAME" rules="required">
                            <div slot-scope="{ errors }">
                              <select v-model="currencyDetails.mobile_ios_status" class="form-control">
                                <option value>{{ $t('CURRENCY_DETAILS_PAGE_CHOSE_A_STATUS_LABEL') }}</option>
                                <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                              </select>
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{ errors[0] }}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                      </CCol>
                      <CCol sm="12" lg="7" md="7">
                        <ValidationProvider name="CURRENCY_DETAILS_PAGE_MOBILE_IOS_MAINTENANCE_END_DATE_FIELD_NAME" rules="">
                          <div slot-scope="{ errors }">
                            <div role="group" class="form-group">
                              <date-picker
                                v-model="currencyDetails.mobile_ios_maintenance_end_date"
                                valueType="date"
                                type="datetime"
                              ></date-picker>
                            </div>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_MOBILE_ANDROID_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <CRow>
                      <CCol sm="12" lg="5" md="5">
                        <ValidationProvider name="CURRENCY_DETAILS_PAGE_MOBILE_ANDROID_STATUS_FIELD_NAME" rules="required">
                          <div slot-scope="{ errors }">
                            <select v-model="currencyDetails.mobile_android_status" class="form-control">
                              <option value>{{ $t('CURRENCY_DETAILS_PAGE_CHOSE_A_STATUS_LABEL') }}</option>
                              <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                            </select>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                      <CCol sm="12" lg="7" md="7">
                        <ValidationProvider name="CURRENCY_DETAILS_PAGE_MOBILE_ANDROID_MAINTENANCE_END_DATE_FIELD_NAME" rules="">
                          <div slot-scope="{ errors }">
                            <div role="group" class="form-group">
                              <date-picker
                                v-model="currencyDetails.mobile_android_maintenance_end_date"
                                valueType="date"
                                type="datetime"
                              ></date-picker>
                            </div>
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </div>
                        </ValidationProvider>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_DECIMALS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <span class="form-control-plaintext">{{ currencyDetails.decimals }}</span>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_NETWORK_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_NETWORK_FIELD_NAME" rules="required|max:100">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.network" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_DEFAULT_FLG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_DEFAULT_FLG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.default_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_ORDER_INDEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_ORDER_INDEX_FIELD_NAME" rules="min_value:0">
                      <div slot-scope="{ errors }">
                        <currency-input class="input-field form-control" v-model="currencyDetails.order_index" :precision="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_DOWNLOAD_TRANSACTIONS_CSV_FLG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_DOWNLOAD_TRANSACTIONS_CSV_FLG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.download_transactions_csv_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_EXPLORE_URL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_EXPLORE_URL_FIELD_NAME" rules="max:500">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.explore_url" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_TRANSACTION_FORMAT_LINK_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_TRANSACTION_FORMAT_LINK_FIELD_NAME" rules="max:500">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.transaction_format_link" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_ADDRESS_FORMAT_LINK_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_ADDRESS_FORMAT_LINK_FIELD_NAME" rules="max:500">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.address_format_link" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="currencyDetails.type == 'CONTRACT'">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_SC_TOKEN_ADDRESS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <StakingExplorerLink
                        type="address"
                        :currencySymbol="currencyDetails.symbol"
                        :value="currencyDetails.sc_token_address"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_WEB_SITE_URL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_WEB_SITE_URL_FIELD_NAME" rules="required|max:500">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.web_site_url" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_DESCRIPTION_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_DESCRIPTION_FIELD_NAME" rules="max:1000">
                      <div slot-scope="{ errors }">
                        <textarea v-model="currencyDetails.description" rows="5" class="form-control"></textarea>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('CURRENCY_DETAILS_PAGE_CONFIG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="CURRENCY_DETAILS_PAGE_CONFIG_FIELD_NAME" rules="json">
                      <div slot-scope="{ errors }">
                        <textarea v-model="currencyDetails.config" rows="5" class="form-control"></textarea>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" class="btn btn-secondary btn-cancel">{{
                      $t('CURRENCY_DETAILS_PAGE_CANCEL_BUTTON')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('CURRENCY_DETAILS_PAGE_UPDATE_BUTTON')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <StakingModal :linkTo="currencyListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingModal from '@/components/StakingModal';
import StakingBadge from '@/components/StakingBadge';
import StakingExplorerLink from '@/components/StakingExplorerLink';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'EditCurrency',
  components: {
    VclList,
    StakingModal,
    StakingBadge,
    StakingExplorerLink,
    DatePicker,
  },
  data() {
    return {
      currencyId: null,
      currencyDetails: null,
      statuses: [],
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      warningLeavePage: false,
      currencyListUrl: '/admin/setting/currencies',
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const currencyId = this.$route.params.currencyId;
    this.checkPermissions();

    if (currencyId) {
      this.isEdit = true;
      this.currencyId = currencyId;
      await Promise.all([this.getCurrencyDetails(currencyId), this.getCurrencyStatuses()]);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      await Promise.all([this.getCurrencyStatuses()]);
      this.currencyDetails = {
        contract_flg: false,
        from_flg: false,
        to_flg: false,
        fix_rate_flg: false,
      };
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'UPDATE_CURRENCY_DETAIL') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getCurrencyStatuses() {
      try {
        const result = await this.$http.get(endpoints.getDropdownCurrencyStatuses);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'CURRENCY_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getCurrencyDetails(currencyId) {
      try {
        const res = await this.$http.get(endpoints.getCurrencyDetails(currencyId));
        this.currencyDetails = res.data;
        ['web_maintenance_end_date', 'mobile_ios_maintenance_end_date', 'mobile_android_maintenance_end_date'].forEach((propertyName) => {
          if (this.currencyDetails[propertyName]) {
            this.currencyDetails[propertyName] = moment(this.currencyDetails[propertyName]).toDate();
          }
        });
        if (this.currencyDetails.config) {
          let config = JSON.stringify(this.currencyDetails.config, null, 2);
          this.currencyDetails.config = config;
        }
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToCurrencyList() {
      this.$router.push(this.currencyListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.currencyListUrl);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      return this.updateCurrency();
    },
    async updateCurrency() {
      if (this.currencyDetails.isProcessing) {
        return;
      }

      this.currencyDetails.isProcessing = true;
      let config = this.currencyDetails.config || null;
      config = config ? JSON.parse(config) : null

      try {
        const data = {
          name: this.currencyDetails.name,
          icon: this.currencyDetails.icon,
          order_index: this.currencyDetails.order_index,
          description: this.currencyDetails.description,
          config: config,
          default_flg: this.currencyDetails.default_flg,
          download_transactions_csv_flg: this.currencyDetails.download_transactions_csv_flg || false,
          status: this.currencyDetails.status,
          mobile_ios_status: this.currencyDetails.mobile_ios_status,
          mobile_android_status: this.currencyDetails.mobile_android_status,
          network: this.currencyDetails.network,
          explore_url: this.currencyDetails.explore_url || null,
          transaction_format_link: this.currencyDetails.transaction_format_link || null,
          address_format_link: this.currencyDetails.address_format_link || null,
          web_site_url: this.currencyDetails.web_site_url || null,
          web_maintenance_end_date: this.currencyDetails.web_maintenance_end_date || null,
          mobile_ios_maintenance_end_date: this.currencyDetails.mobile_ios_maintenance_end_date || null,
          mobile_android_maintenance_end_date: this.currencyDetails.mobile_android_maintenance_end_date || null,
        };
        const result = await this.$http.put(endpoints.updateCurrency(this.currencyId), data);

        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TEXT'),
        });
        this.backToCurrencyList();
      } catch (err) {
        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.currency-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
      .currency-type {
        padding-top: 10px;
      }

      .mx-datepicker {
        display: block;
        width: 100%;
      }

      &.is-invalid {
        .mx-input {
          border: 1px solid #ff5252;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
